var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { FlexJustifyContent, Grid, GridItem } from '../../common/components/Grid';
import RichContent from '../../common/components/RichText/RichContent';
import SeoTable from './SeoTable';
import { SeoBlockType } from './types';
var SeoSection = function (_a) {
    var type = _a.type, content = _a.content, options = _a.options, _b = _a.isContentCenterAligned, isContentCenterAligned = _b === void 0 ? false : _b, _c = _a.isContentWithReducedWidth, isContentWithReducedWidth = _c === void 0 ? false : _c, _d = _a.__legacy_shop_reboot_max_width, __legacy_shop_reboot_max_width = _d === void 0 ? true : _d;
    return (React.createElement(Grid, __assign({ legacyShopRebootMaxWidth: __legacy_shop_reboot_max_width }, (isContentWithReducedWidth && {
        justifyContent: FlexJustifyContent.CENTER,
    }), { "data-testid": "seo-section-grid" }),
        React.createElement(GridItem, __assign({}, (isContentWithReducedWidth && { width: [1, 6 / 8, 8 / 12] })), type === SeoBlockType.TableOfContent ? (React.createElement(SeoTable, __assign({}, options, { isContentCenterAligned: isContentCenterAligned }))) : (React.createElement(RichContent, { content: content, isCenterAligned: isContentCenterAligned, "data-testid": "seo-section" })))));
};
export default SeoSection;
