var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { backgroundColor, color, fontSize, lineHeight, space, textAlign, } from 'styled-system';
import { Link } from '../../common/components/Link';
import { Text } from '../../common/components/Text';
import { textDecoration } from '../../common/theme/system-utilities';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), textAlign);
Container.defaultProps = {
    role: 'navigation',
};
var Headline = styled(Text)(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
Headline.defaultProps = {
    as: 'h2',
    color: 'sys.neutral.text.strong',
    fontSize: ['fs_18', null, 'fs_20'],
    lineHeight: 'lh_20',
    mb: 'sp_4',
    mt: 'sp_0',
    fontWeight: 'normal',
};
var List = styled.ul(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), space, backgroundColor);
List.defaultProps = {
    bg: 'sys.neutral.background.weak',
    py: ['sp_16', null, null, 'sp_20'],
    px: 'sp_32',
};
var ListItem = styled.li(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), color, fontSize, lineHeight);
ListItem.defaultProps = {
    fontSize: ['fs_16', null, null, 'fs_18'],
    lineHeight: ['lh_20', null, null, 'lh_24'],
    color: 'sys.neutral.text.strong',
};
var StyledLink = styled(Link)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), textDecoration);
StyledLink.defaultProps = __assign(__assign({}, Link.defaultProps), { textDecoration: 'underline' });
var SeoTable = function (_a) {
    var isContentCenterAligned = _a.isContentCenterAligned, headline = _a.headline, headings = _a.headings;
    return (React.createElement(Container, __assign({}, (isContentCenterAligned && { textAlign: 'center' })),
        React.createElement(Headline, null, headline),
        React.createElement(List, null, headings.map(function (_a) {
            var name = _a.name, anchor = _a.anchor;
            return (React.createElement(ListItem, { key: name },
                React.createElement(StyledLink, { href: anchor }, name)));
        }))));
};
export default SeoTable;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
